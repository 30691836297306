<template>
    <v-app>
        <v-container fill-height>
            <v-row>
                <v-col cols="12" class="notfound">
                    <img src="@/assets/img/404.svg" alt="Not Found" />
                    <h1>{{$t('notFound.text')}}</h1>
                    <v-btn
                        rounded
                        dark
                        class="btn--main"
                        @click="$router.push('/')"
                    >{{$t('notFound.button')}}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data: () => ({})
};
</script>
